/**
 * Get popup features for centering popup
 *
 * @param width
 * @param height
 * @returns {string}
 */
export const getCenterPopupFeatures = (width, height) => {
  const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;

  return `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`;
};
