import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '../DropDown/DropDown';
import * as S from './styled';
import LoginHubIcon from './images/login-icon-hub.svg';
import LoginPortalIcon from './images/login-icon-portal.svg';
import useLogin from '../../../hooks/useLogin';
import { useSigninSilent } from '../../../hooks/useSigninSilent';
import { useTokenRenewal } from '../../../hooks/useTokenRenewal';
import useWindowEvents from '../../../hooks/useWindowEvents';
import { useCrossSiteLoginState } from '../../../hooks/useCrossSiteLoginState';

export const LoginButton = ({ portal }) => {
  const { login, isLoggedIn, logout } = useLogin();

  const isRefreshed = useSigninSilent();
  useTokenRenewal();
  useWindowEvents(isRefreshed);

  useCrossSiteLoginState();

  return (
    <S.LoginContainer>
      {isLoggedIn ? (
        <DropDown logout={logout} />
      ) : (
        <S.LoginButton onClick={login} className="login">
          <S.LoginIcon
            src={portal === 'isPortal' ? LoginPortalIcon : LoginHubIcon}
            alt="Login"
          />
        </S.LoginButton>
      )}
    </S.LoginContainer>
  );
};

LoginButton.propTypes = {
  portal: PropTypes.oneOf(['isPortal', 'notPortal']),
};
