import redirectTo from './redirectTo';
import { trackLogin } from '../../analytics/track';

export const LastVistedPageRedirectKey = 'lastVistedPage';
export const RegisterRedirectKey = 'registerRedirectPage';

export const signinCallback = (user) => {
  trackLogin('login signinCallback', 'login', user?.profile.sub);
  redirectTo(LastVistedPageRedirectKey);
};

export const signinPopupCallback = (user) => {
  trackLogin('login signinPopupCallback', 'login', user?.profile.sub);
  redirectTo(LastVistedPageRedirectKey);
};

export const signinRedirectCallback = (user) => {
  trackLogin('login signinRedirectCallback', 'login', user?.profile.sub);
  redirectTo(LastVistedPageRedirectKey);
};

export const removeUserCallback = () => {
  trackLogin('logout onRemoveUser', 'logout');
  redirectTo(LastVistedPageRedirectKey);
};
