import { useEffect } from 'react';
import useLogin from './useLogin';
import useAccount from './useAccount';

/**
 *
 * This hook adds support for some legacy code that is still using the
 * window object to access the login/logout/register functions.
 *
 * @param isRefreshed Whether the user's access token has been refreshed
 */
const useWindowEvents = (isRefreshed) => {
  const { logout, login, register, isLoggedIn } = useLogin();
  const account = useAccount();

  useEffect(() => {
    window.logoutUser = logout;
    window.defaultLoginHandler = login;
    window.registerHandler = register;

    window.getUser = () => {
      // When the user does not have a refreshed access token, we don't want to
      // return the user object, as it might be outdated.
      if (!isRefreshed) {
        return Promise.resolve(undefined);
      }

      // When the user is logged in, we return the user object.
      return Promise.resolve(isLoggedIn ? account : null);
    };
  }, [isLoggedIn, account, login, logout, register, isRefreshed]);
};

export default useWindowEvents;
