import styled from 'styled-components';
import { fromTabletSmall, fromDesktop } from '../../../helpers/variables';

export const BarStyle = styled.div`
  all: initial;
  height: ${({ isPortal }) => (isPortal ? '80px' : '56px')};
  font-family: 'Exo 2', Helvetica, sans-serif;
  transition: height 0.2s ease-in-out;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
`;

export const BarContainer = styled.div`
  all: initial;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ isPortal }) => (isPortal ? '80px' : '56px')};
  box-sizing: border-box;
  padding: 0 16px;

  @media (${fromTabletSmall}) {
    padding: 0 32px;
  }
`;

export const Logo = styled.a`
  all: initial;
  display: block;
  padding: 0;
  width: 106px;
  margin-top: -4px;
  cursor: pointer;
`;

export const LogoImg = styled.img`
  width: 106px;
  height: 33px;
  transform: rotate(-3deg);
`;

export const Right = styled.div`
  all: initial;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  @media (${fromTabletSmall}) {
    width: 45%;
  }
`;

export const NpoLogo = styled.a`
  all: initial;

  @media (${fromTabletSmall}) {
    width: auto;
    display: block;
  }
`;

export const NpoLogoImg = styled.img`
  display: block;
  height: 36px;
  width: 36px;
`;

export const Link = styled.a`
  all: initial;
  font-family: 'Exo 2', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 15px;
  line-height: 54px;
  display: block;
  cursor: pointer;
  font-weight: 600;
  transition: color 0.2s ease-in-out;
  color: #fff;
  border-bottom: 4px solid;

  @media (${fromTabletSmall}) {
    font-size: 14px;
    margin: 0 10px;
  }

  @media (${fromDesktop}) {
    font-size: 16px;
  }

  &:hover {
    color: #fff;
    border-color: white;
    text-decoration: none;
  }

  border-color: ${(props) => (props.open === 'true' ? 'white' : 'transparent')};
`;
