/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { StyledButtonLink, ButtonLinkContainer } from './styled';
import Icon from './icon/heart';

const ButtonLink = (props) => (
  <ButtonLinkContainer>
    <StyledButtonLink className="ABtestWordLidKnop" {...props}>
      <Icon />
      <span>Sluit je aan!</span>
    </StyledButtonLink>
  </ButtonLinkContainer>
);

export default ButtonLink;
