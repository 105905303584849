/**
 * default claim set
 */
const claim = {
  username: null,
  photos: null,
  given_name: null,
  middle_name: null,
  family_name: null,
  date_of_birth: null,
  primary_address: null,
  roles: null,
  relation_number: null,
};

/**
 * Claims represent what the client (this browser/user) says it is allowed to see when
 * logged in using the token
 */
const claims = JSON.stringify({
  // userinfo is applicable on the access_token meaning, the server would be able to request
  // this information using the access_token
  userinfo: claim,
});

export default claims;
