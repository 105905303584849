import { useEffect } from 'react';
import { getOpenIdConfig } from '../auth/openIdConfig';
import useLogin from './useLogin';

/**
 * This hook checks if there is a cookie value set for the logged-in
 * user. If there is no cookie, but the user is still logged-in,
 * the user must have logged out on another subdomain sharing the same
 * cookie. In that case, we log the user out.
 */
export const useCrossSiteLoginState = () => {
  const { logout, loginSilent, isLoggedIn } = useLogin();

  useEffect(() => {
    const openIdConfig = getOpenIdConfig();

    const interval = setInterval(() => {
      const authCookie = document.cookie.split(';').find((cookie) => {
        const [key] = cookie.trim().split('=');

        const openIdKey = encodeURIComponent(
          ['oidc.user', openIdConfig.authority, openIdConfig.client_id].join(
            ':'
          )
        );

        return key === openIdKey;
      });

      if (!authCookie && isLoggedIn) {
        // If there is no cookie, but the user still appears to be logged in,
        // the user should be logged out.
        logout();
      } else if (authCookie && !isLoggedIn) {
        // If there is a cookie, but the user is not logged in, the user should
        // be logged in, so let's try a silent login.
        loginSilent();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isLoggedIn, logout, loginSilent]);
};
