import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Dropdown,
  Link,
  Icon,
  NamesContainer,
  Nickname,
  Name,
} from './styled';
import AccountIcon from './images/account-icon.svg';
import LogoutIcon from './images/logout-icon.svg';
import settingsIcon from './images/settings.svg';
import AvatarFallback from './images/avatar-fallback.svg';
import config from '../../../config';
import useAccount from '../../../hooks/useAccount';

export const DropDown = ({ logout }) => {
  const user = useAccount();
  const [open, setOpen] = useState(false);
  const node = useRef();

  const toggleDropDown = () => setOpen((prevOpen) => !prevOpen);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      // ignore clicks on the component itself
      if (node.current !== e.target) {
        return;
      }

      toggleDropDown();
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, []);

  return (
    <div ref={node} className="user-dropdown-container">
      <Avatar
        src={user.profile.profilePicture || AvatarFallback}
        alt="Login"
        onClick={toggleDropDown}
        className="avatar"
      />
      {open && (
        <Dropdown className="user-dropdown">
          <NamesContainer className="names-container">
            <Nickname className="nickname">{user.profile.username}</Nickname>
            <Name className="displayName">
              {[user.profile.preferred_username].join(' ')}
            </Name>
          </NamesContainer>
          <Link href={config.urls.profiel} target="_blank">
            <Icon type="account" src={AccountIcon} alt="Jouw profiel" />
            Profiel
          </Link>
          <Link href={config.urls.mijnbnnvara} target="_blank">
            <Icon
              type="settings"
              src={settingsIcon}
              alt="Accountinstellingen"
            />
            Accountinstellingen
          </Link>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={logout} className="logout">
            <Icon type="logout" src={LogoutIcon} alt="Uitloggen" />
            Uitloggen
          </Link>
        </Dropdown>
      )}
    </div>
  );
};

DropDown.propTypes = {
  logout: PropTypes.func.isRequired,
};
