import styled, { keyframes } from 'styled-components';

export const StyledButtonLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #e60000;
  border-radius: 50%;
  z-index: 999999;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: rgb(255, 34, 34);
    transition: all 0.2s ease-in-out;
  }
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
`;

export const ButtonLinkContainer = styled.div`
  a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #e60000;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
  }

  span {
    position: absolute;
    margin-top: 42px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 8px 16px;
    font-family: 'Exo 2', Helvetica, sans-serif;
    color: #000000;
    font-weight: 900;
    font-size: 14px;
    -webkit-animation: ${fade} 8s;
    animation: ${fade} 8s;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0;
    z-index: 999;
  }

  @media (min-width: 768px) {
    a {
      border-radius: 0;
      width: auto;
      height: auto;
      padding: 0 44px;

      span {
        position: relative;
        margin-top: 0;
        -webkit-animation: none;
        animation: none;
        opacity: 1;
        background: transparent;
        box-shadow: none;
        color: #ffffff;
        font-size: 14px;
        line-height: 40px;
        padding: 0;
      }
    }

    svg {
      display: none;
    }
  }
`;
