import { trackNpo } from './NpoAnalytics/helpers';
import GoogleAnalytics from './GoogleAnalytics/GoogleAnalytics';

/**
 * GA event properties and Piano Analytics properties to track, both have their own structure and therefore this is split in to two parameters
 * @param { category, action, label, value } gaProperties
 * @param { name, properties } pianoProperties
 */
export function track(gaProperties, pianoProperties) {
  if (gaProperties) {
    const { category, action, label } = gaProperties;
    /** When migrated from UA to GA4 we can pass a custom event with the value object containing extra properties
     * See: https://bnnvara.atlassian.net/browse/BWG-4521
     */
    GoogleAnalytics.track(category, action, label);
  }
  if (pianoProperties) {
    try {
      const { name, properties } = pianoProperties;
      trackNpo(name, properties);
    } catch (error) {
      console.log(error);
    }
  }
}

export const trackLogin = (
  loginEvent,
  label,
  userId = undefined,
  silent = false
) => {
  if (window === undefined) return;

  /** Optional browser session_id */
  const sessionId = /SESS\w*ID=([^;]+)/i.test(document.cookie)
    ? RegExp.$1
    : false;

  const value = {
    time: new Date().toString(),
    event: loginEvent,
    user: userId,
    session_id: sessionId,
    silent,
    platform: 'site',
    broadcaster: 'bnnvara',
  };

  const gaProperties = {
    category: loginEvent,
    action: 'click',
    label,
    value: {
      ...value,
      pageUrl: window.location.href,
    },
  };

  const pianoProperties = {
    name: `click.action`,
    properties: {
      click: loginEvent,
      click_chapter1: label,
      ...value,
    },
  };

  track(gaProperties, pianoProperties);
};
