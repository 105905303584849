/* eslint-disable */

import isCookiesAccepted from '../../helpers/cookies';
import config from '../../config';

class Analytics {
  constructor(options) {
    this.trackingId = options.trackingId;
    this.trackerName = options.trackerName || 'balkTracker';
    this.defaultTrackerName = 't0';
    this.init();
  }

  track(category, action, label, value) {
    if (isCookiesAccepted()) {
      window.ga(`${this.trackerName}.send`, {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        value,
      });
    }
  }

  init() {
    if (typeof window !== 'undefined') {
      if (window.hasOwnProperty('ga')) {
        this.createTracker(this.trackerName);
      } else {
        this.loadGoogleAnalytics();
      }
    }
  }

  loadGoogleAnalytics() {
    if (isCookiesAccepted()) {
      // Standard Google Analytics snippets
      (function (i, s, o, g, r, a, m) {
        i.GoogleAnalyticsObject = r;
        (i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        'script',
        '//www.google-analytics.com/analytics.js',
        'ga'
      );

      this.createTracker(this.defaultTrackerName);
    }
  }

  createTracker(trackerName) {
    this.trackerName = trackerName;

    window.ga('create', {
      trackingId: this.trackingId,
      cookieDomain: 'auto',
      name: trackerName,
    });

    window.ga(`${this.trackerName}.send`, {
      hitType: 'pageview',
      page: document.location.href,
    });
  }
}

// Export class as a singleton
const GoogleAnalytics = new Analytics({
  trackingId: config.trackingId,
});

export default Object.freeze(GoogleAnalytics);
