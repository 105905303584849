// Load polyfills
import 'whatwg-fetch';
import 'es6-promise/auto';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { createGlobalStyle } from 'styled-components';
import { AuthProvider } from 'react-oidc-context';
import { Bar } from './components/BnnvaraBar/Bar/Bar';
import { getOpenIdConfig, setExternalOpenIdConfig } from './auth/openIdConfig';

createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Exo+2:400,500,600,900');
`;

const createBnnvaraBar = () => {
  const bnnvaraBar = document.createElement('div');
  bnnvaraBar.id = 'bnnvarabalk';
  bnnvaraBar.style.minHeight = '56px';

  return bnnvaraBar;
};

const initHub = {
  async showBar(
    externalOpenIdConfig,
    portal,
    showLogin,
    showMenu,
    npo,
    loginUrl,
    loginCallBack,
    logoutUrl
  ) {
    const bnnvaraBar = createBnnvaraBar();
    const root = createRoot(bnnvaraBar);

    document.body.insertBefore(bnnvaraBar, document.body.firstChild);

    // Set the external openId config, this is based on the environment
    setExternalOpenIdConfig(externalOpenIdConfig);

    const openIdConfig = getOpenIdConfig();

    root.render(
      // eslint-disable-next-line react/jsx-props-no-spreading
      <AuthProvider {...openIdConfig}>
        <Bar
          portal={portal}
          showLogin={showLogin}
          showMenu={showMenu}
          npo={npo}
          loginUrl={loginUrl}
          loginCallBack={loginCallBack}
          logoutUrl={logoutUrl}
          loadPianoAnalytics
        />
      </AuthProvider>
    );
  },
};

export { initHub };
