const config = {
  appId: '7773701c-3962-45cb-bdf9-2b8480ae9bcb',
  trackingId: 'UA-100721770-2',
  pianoAnalytics: {
    npoAnalyticsId: '630073',
    paSDK: 'https://tag.aticdn.net/piano-analytics.js',
    collectDomain: 'https://atconnect.npo.nl',
  },
  endpoints: {
    articles:
      'https://fapi.bnnvara.nl/api/v1/data/articles/filter/?from=0&size=6&exclude_from_bar=0',
    titles: 'https://assets.bnnvara.nl/json/bnnvara.json',
  },
  openId: {
    customerId: process.env.OPENID_CUSTOMER_ID,
  },
  urls: {
    bnnvara: 'https://www.bnnvara.nl',
    profiel: 'https://www.bnnvara.nl/profiel',
    npo: 'https://www.npo.nl',
    zapp: 'https://www.zapp.nl/',
    titles: 'https://ps-bnnvara.bnnvara.nl/programmas',
    mijnbnnvara: 'https://mijn.bnnvara.nl',
  },
};

export default config;
