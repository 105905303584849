import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '../DropDown/DropDown';
import * as S from './styled';
import LoginHubIcon from './images/login-icon-hub.svg';
import LoginPortalIcon from './images/login-icon-portal.svg';

export const LoginButton = ({ portal, login, logout, isLoggedIn }) => {
  return (
    <S.LoginContainer>
      {isLoggedIn ? (
        <DropDown logout={logout} />
      ) : (
        <S.LoginButton onClick={login} className="login">
          <S.LoginIcon
            src={portal === 'isPortal' ? LoginPortalIcon : LoginHubIcon}
            alt="Login"
          />
        </S.LoginButton>
      )}
    </S.LoginContainer>
  );
};

LoginButton.propTypes = {
  portal: PropTypes.oneOf(['isPortal', 'notPortal']),
  login: PropTypes.func,
  logout: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};
