import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

const trySigninSilent = async (auth) => {
  if (auth.isLoading) {
    return false;
  }

  try {
    // `result` can sometimes be null on a failure (instead of throwing)
     await auth.signinSilent();
  } catch (signInSilentError) {
    await auth.signoutSilent();

    return false;
  }

  return true;
};

/**
 * When the user is not authenticated, but the user object is set, we can
 * assume that the user's access token has expired. We can then try to
 * silently sign the user in again with the refresh token.
 *
 * If the access token could not be refreshed, the user will be signed out.
 * This failsafe is mostly built-in for BWG-4001 - when transitioning from
 * an old to new auth system, where some users might encounter an infinite
 * number of refresh token requests that resulted in a 400 error.
 */
export const useSigninSilent = () => {
  const auth = useAuth();
  const [tokenRefreshed, setTokenRefreshed] = useState(false);

  useEffect(() => {
    const trySignin = async () => {
      const refreshed = await trySigninSilent(auth);
      setTokenRefreshed(refreshed);
    };

    if (!tokenRefreshed) {
      trySignin();
    }
  }, [auth, tokenRefreshed]);

  return tokenRefreshed;
};
