import styled from 'styled-components';
import { fromTabletSmall } from '../../../helpers/variables';

export const Avatar = styled.img`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 0 10px;
  object-fit: cover;

  @media (${fromTabletSmall}) {
    height: 40px;
    width: 40px;
  }
`;

export const Dropdown = styled.div`
  width: 240px;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 38px;
  top: 70px;
  display: block;
  z-index: 999999999;

  &::before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -5px;
    right: 16px;
  }

  @media (${fromTabletSmall}) {
    right: 24px;
    top: 50px;
  }
`;

export const Link = styled.a`
  cursor: pointer;
  padding: 14px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'Exo BNNVARA', Verdana, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;

  &:hover {
    color: #c00;
  }

  &:last-child {
    border: none;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  max-height: 24px;
`;

export const NamesContainer = styled.div`
  padding: 16px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Nickname = styled.p`
  color: black;
  font-weight: bold;
  font-family: 'Exo 2', Helvetica, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const Name = styled.p`
  color: black;
  font-family: 'Exo 2', Helvetica, sans-serif;
  font-weight: light;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: rgba(119, 119, 119, 0.8);
  text-transform: capitalize;
`;
