/* eslint-disable camelcase */

/**
 * Monkey patches the given CookieStorage instance by only storing the access_token and id_token
 * in the cookie. This is done to prevent the cookie from growing too large.
 *
 * The rest of the methods are copied from the CookieStorage class.
 *
 * @param cookieStorage
 * @return {{removeItem, clear, getItem, setItem(*, *): void, key}|null}
 */
export const createUserStorage = (cookieStorage) => {
  return {
    setItem(key, value) {
      const { id_token, profile, ...rest } = JSON.parse(value);

      cookieStorage.setItem(key, JSON.stringify(rest));
    },
    getItem: cookieStorage.getItem.bind(cookieStorage),
    removeItem: cookieStorage.removeItem.bind(cookieStorage),
    clear: cookieStorage.clear.bind(cookieStorage),
    key: cookieStorage.key.bind(cookieStorage),
  };
};
