import React from 'react';
import PropTypes from 'prop-types';
import config from '../../../config';
import { LoginButton } from '../../Login/LoginButton/LoginButton';
import logo from './images/bnnvara_logo.svg';
import npologo from './images/npologo.svg';
import zapplogo from './images/logo-zapp.svg';

import { track } from '../../../analytics/track';
import ButtonLink from '../Link';
import * as S from './styled';
import useLogin from '../../../hooks/useLogin';
import { useSigninSilent } from '../../../hooks/useSigninSilent';
import { useTokenRenewal } from '../../../hooks/useTokenRenewal';
import useWindowEvents from '../../../hooks/useWindowEvents';
import { useCrossSiteLoginState } from '../../../hooks/useCrossSiteLoginState';

export const Bar = ({
  portal,
  npo,
  showLogin,
  loginUrl,
  loginCallBack,
  logoutUrl,
}) => {
  const { login, isLoggedIn, logout } = useLogin();

  const isRefreshed = useSigninSilent();
  useTokenRenewal();
  useWindowEvents(isRefreshed);

  useCrossSiteLoginState();

  const handleLogoClick = (category, label) => {
    const gaProperties = { category, action: 'click', label };
    const npoProperties = {
      name: 'click.action',
      properties: { click: category, click_chapter1: label },
    };

    track(gaProperties, npoProperties);

    window.location.href = 'https://www.bnnvara.nl';
  };

  return (
    <>
      <S.BarStyle aria-hidden="true" isPortal={portal === 'isPortal'}>
        <S.BarContainer isPortal={portal === 'isPortal'}>
          {npo !== 'zapp' && (
            <S.Logo onClick={() => handleLogoClick('Logo', 'bnnvara')}>
              <S.LogoImg src={logo} alt="BNNVARA" height="32" />
            </S.Logo>
          )}
          {npo === 'zapp' && <S.LogoImg src={logo} alt="BNNVARA" height="32" />}
          <S.Right>
            <ButtonLink
              value="Word lid"
              href="https://bnnvara.nl/wijzijnvoor"
            />
            <LoginButton
              show={showLogin === 'showLogin'}
              login={() => {
                login(loginUrl, loginCallBack);
              }}
              portal={portal}
              logout={() => {
                logout(logoutUrl);
              }}
              isLoggedIn={isLoggedIn}
            />
            {npo === 'npo' && (
              <S.NpoLogo
                href={config.urls.npo}
                target="_blank"
                onClick={() => handleLogoClick('Logo', 'npo')}
              >
                <S.NpoLogoImg src={npologo} alt="NPO" height="32" />
              </S.NpoLogo>
            )}
            {npo === 'zapp' && (
              <S.NpoLogo
                href={config.urls.zapp}
                target="_blank"
                onClick={() => handleLogoClick('Logo', 'zapp')}
              >
                <S.NpoLogoImg src={zapplogo} alt="Zapp" height="32" />
              </S.NpoLogo>
            )}
          </S.Right>
        </S.BarContainer>
      </S.BarStyle>
    </>
  );
};

Bar.propTypes = {
  portal: PropTypes.oneOf(['isPortal', 'notPortal']),
  npo: PropTypes.oneOf(['npo', 'zapp']),
  showLogin: PropTypes.oneOf(['showLogin', 'hideLogin']),
  loginUrl: PropTypes.string,
  loginCallBack: PropTypes.func,
  logoutUrl: PropTypes.string,
};
