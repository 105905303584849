import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  position: relative;
  transform: scale(0.7);
  top: 1px;
`;

export default () => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 40 40"
    className="icon sc-1tuu6f0-0 bwxaHv"
  >
    <rect width="100px" height="100px" fill="none" />
    <path
      d="M20,10.28a10.11,10.11,0,0,1,1.48-1V0h-3V9.22A10.37,10.37,0,0,1,20,10.28Z"
      fill="#ffffff"
    />
    <path
      d="M20,37.41,7,24.52a8.59,8.59,0,0,1,0-12.2,8.73,8.73,0,0,1,12.27,0L20,13l.72-.72a8.73,8.73,0,0,1,12.27,0,8.57,8.57,0,0,1,0,12.2ZM13.14,11.8a6.67,6.67,0,0,0-4.73,1.94,6.59,6.59,0,0,0,0,9.36L20,34.59,31.58,23.1a6.57,6.57,0,0,0,0-9.36h0a6.72,6.72,0,0,0-9.45,0L20,15.86l-2.13-2.12A6.71,6.71,0,0,0,13.14,11.8Z"
      fill="#ffffff"
    />
    <path
      d="M9.77,8.34,7.56,3.07,4.8,4.23,7.08,9.68A10.91,10.91,0,0,1,9.77,8.34Z"
      fill="#ffffff"
    />
    <path
      d="M32.45,9.38,35.14,4.3,32.49,2.9l-2.8,5.29A10.39,10.39,0,0,1,32.45,9.38Z"
      fill="#ffffff"
    />
  </Icon>
);
