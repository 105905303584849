import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { trackLogin } from '../analytics/track';

/**
 * Listens to the token renewal event and sends a token renewal tracking event,
 * on success or failure.
 */
export const useTokenRenewal = () => {
  const auth = useAuth();

  // Token renewal tracking.
  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(async () => {
      const userId = auth?.user?.profile.sub;

      trackLogin('login_token', 'login', userId, true);

      try {
        const user = await auth.signinSilent();

        trackLogin('login_success', 'login', user?.profile.sub, true);
      } catch (error) {
        trackLogin(`login_failure`, 'login', userId, true);
      }
    });
  }, [auth]);
};
