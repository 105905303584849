/**
 * Gets the URL stored in localStorage.
 *
 * @param redirectKey
 * @returns {null|string}
 */
export const getRedirect = (redirectKey) => {
  const redirect = window.localStorage.getItem(redirectKey);

  if (redirect && redirect !== window.location.href) {
    return redirect;
  }

  return null;
};

/**
 * Possibly redirects to the URL stored in localStorage.
 *
 * @param redirectKey
 */
const redirectTo = (redirectKey) => {
  const redirect = getRedirect(redirectKey);

  if (redirect) {
    window.localStorage.removeItem(redirectKey);
    window.location.assign(redirect);
  }
};

export default redirectTo;
