import { useAuth } from 'react-oidc-context';
import {
  LastVistedPageRedirectKey,
  RegisterRedirectKey,
} from '../auth/helpers/callbacks';
import { trackLogin } from '../analytics/track';

const STATUS_IDLE = { name: 'idle', error: null };
const STATUS_LOADING = { name: 'loading', error: null };
const STATUS_SUCCESS = { name: 'success', error: null };

/**
 * Reused status out of the AkamaiAdapter
 */
const getStatus = (auth) => {
  if (auth.isAuthenticated) {
    return STATUS_SUCCESS;
  }

  if (auth.isLoading || auth.activeNavigator === 'signinSilent') {
    return STATUS_LOADING;
  }

  return STATUS_IDLE;
};

/**
 *
 * Wrapper around the new useAuth();
 * @returns {{logout: logout, isLoggingIn: boolean | undefined, isLoggedIn: boolean, login: login, error: string | undefined, status: ({name: string, error: null}|{name: string, error: null}|{name: string, error: null}), register: register, token: string | undefined}}
 */
const useLogin = () => {
  const auth = useAuth();

  return {
    isLoggedIn: auth.isAuthenticated,
    isLoggingIn: auth.activeNavigator?.startsWith('signin'),
    status: getStatus(auth).name,
    login: (loginUrl, loginCallBack) => {
      window.localStorage.setItem(
        LastVistedPageRedirectKey,
        window.location.href
      );

      auth.clearStaleState().then(() => {
        auth
          .signinRedirect()
          .then((user) => {
            trackLogin('login_success', 'login', user?.profile.sub);
            if (loginCallBack) {
              loginCallBack();
            }
            // Force a reload of the page to ensure that the user is logged in
            if (loginUrl) {
              window.location.href = loginUrl;
            } else {
              window.location.reload();
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);

            trackLogin(`login_failure`, 'login');
          });
      });
    },
    loginSilent: () => {
      auth
        .signinSilent()
        .then((user) => trackLogin('login_success', 'login', user?.profile.sub))
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);

          trackLogin(`login_failure`, 'login');
        });
    },
    register: () => {
      window.localStorage.setItem(
        RegisterRedirectKey,
        `${window.location.origin}/welkom`
      );

      auth
        .signinRedirect({ prompt: 'create' })
        .then((user) => {
          trackLogin('register_success', 'register', user?.profile.sub);

          // Force a reload of the page to ensure that the user is logged in
          window.location.reload();
        })
        .catch((error) => {
          trackLogin(`register_failure: ${error.toString()}`, 'register');
        });
    },
    logout: (logoutUrl) => {
      auth
        .signoutSilent({ silentRequestTimeoutInSeconds: 1 })
        .then(() => {
          trackLogin('logout_success', 'logout');
          if (logoutUrl) {
            window.location.href = logoutUrl;
          } else {
            // Force a reload of the page to ensure that the user is logged out
            window.location.reload();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);

          trackLogin(`logout_failure`, 'logout');
        });
    },
    error: auth.error?.message,
    token: auth.user?.access_token,
    refreshToken: auth.user?.refresh_token,
  };
};

export default useLogin;
