import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(2, 1);
  }
  40% {
    transform: scale(1);
  }
`;

export const LoginContainer = styled.div`
  margin-right: 16px;
  padding-right: 12px;
  padding-left: 12px;
  max-height: 36px;
  display: flex;
  align-items: center;
  border-right: 2px solid #4d4d4d;
  position: relative;
`;

export const LoginButton = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LoginIcon = styled.img``;

export const Loader = styled.div`
  position: initial;
  margin-right: 16px;
  padding: 0 12px;
  border-right: 2px solid #4d4d4d;
  display: block;
  width: 32px;
  right: 30px;
  transform: rotate(-3deg);
`;

export const LoadBar = styled.div`
  margin: 3px 0;
  height: 3px;
  width: 19px;
  background-color: #c00;
  animation: ${loading} 1s ease-in-out infinite;

  &:nth-child(1) {
    animation-delay: 0;
  }

  &:nth-child(2) {
    animation-delay: 0.09s;
  }

  &:nth-child(3) {
    animation-delay: 0.18s;
  }
`;
