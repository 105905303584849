import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

import getProfileFromJwt from '../helpers/getProfileFromJwt';
import getProfilePicture from '../helpers/getProfilePicture';
import { getOpenIdConfig } from '../auth/openIdConfig';

const defaultProfile = { profile: { UID: '' }, token: '' };

const getAccount = (auth) => {
  if (auth.isAuthenticated && auth.user) {
    const { user } = auth;
    // This is needed to assure the latest data is retrieved at all times.
    // e.g.  a user updates his/her profile picture, this latest profile picture should be retrieved.
    // Instead of using getUser, we do it through the returned jwtToken.
    // This is because this only seems to assure we have the latest data related to a user.
    const token = user.access_token || user.token;

    if (token) {
      const profile = getProfileFromJwt(token);
      // Remove the properties that you renamed (to avoid duplicates) or can be removed.
      const { sub, username, photos, roles, ...rest } = profile;

      return {
        token,
        roles,
        profile: {
          UID: sub,
          profilePicture: getProfilePicture(
            photos,
            getOpenIdConfig().avatarEndpoint
          ),
          username,
          nickname: username,
          ...rest,
        },
      };
    }
  }

  return defaultProfile;
};

/**
 * This hook is used as a standard way to retrieve account info. For example
 * if we define that a 'name' is a combination of firstname, middlename and lastname
 * then we should do that here.
 * The account is a combination of the user's profile and the meta data
 * like the user's session (token)
 *
 * This hook should only be used after login. If you need to check if the user
 * was logged in, use the useLogin hook
 * @returns {{profile: {firstName: *, lastName: *, UID: (any|string), profilePicture?: (string|*), address: *, nickname?: any, name: string, middleName: *, dateOfBirth: *, username?: any}, token: string}}
 */
const useAccount = () => {
  const auth = useAuth();

  return useMemo(() => {
    // eslint-disable-next-line camelcase
    const { profile, token } = getAccount(auth);

    const {
      given_name: firstName,
      middle_name: middleName,
      family_name: lastName,
      date_of_birth: dateOfBirth,
      primary_address: address,
      ...rest
    } = profile;

    return {
      profile: {
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        address,
        ...rest,
        name: [firstName, middleName, lastName].join(' '),
      },
      token,
    };
  }, [auth]);
};

export default useAccount;
