import { WebStorageStateStore } from 'oidc-client-ts';
import { CookieStorage } from 'cookie-storage';
import claims from './helpers/openIdClaims';
import { getCenterPopupFeatures } from './helpers/popupHelper';
import { createUserStorage } from './helpers/createUserStorage';
import { createCookieDomain } from './helpers/createCookieDomain';
import {
  removeUserCallback,
  signinCallback,
  signinPopupCallback,
  signinRedirectCallback,
} from './helpers/callbacks';
import config from '../config';

const { openId: openIdBaseConfig } = config;

// This is dependent on the environment, so we need to set it at runtime
let defaultExternalOpenIdConfig = {
  hostedLoginEndpoint: undefined,
  clientId: undefined,
  redirectUri: undefined,
  avatarEndpoint: undefined,
};

/**
 * Set the external openId config, this is based on the environment
 *
 * @param externalOpenIdConfig
 * @return void
 */
export const setExternalOpenIdConfig = (externalOpenIdConfig) => {
  defaultExternalOpenIdConfig = {
    ...defaultExternalOpenIdConfig,
    ...externalOpenIdConfig,
  };
};

/**
 * Create the OpenId config, compatible with oidc-client-ts
 *
 * @return Object
 */
export const getOpenIdConfig = () => {
  const ssoBaseURL = new URL(
    `${defaultExternalOpenIdConfig.hostedLoginEndpoint}/${openIdBaseConfig.customerId}`
  );

  const cookieStorage = new CookieStorage({
    path: '/',
    secure: true,
    // expires in one month, but should be refreshed when user is on the site
    // and token is about to expire
    expires: new Date(Date.now() + 31 * 24 * 60 * 60 * 1000),
    domain: createCookieDomain(defaultExternalOpenIdConfig.redirectUri),
  });


  return {
    authority: `${ssoBaseURL.href}/login/`,
    client_id: defaultExternalOpenIdConfig.clientId,
    redirect_uri: defaultExternalOpenIdConfig.redirectUri,
    popup_redirect_uri: `${defaultExternalOpenIdConfig.redirectUri}`,
    silent_redirect_uri: `${defaultExternalOpenIdConfig.redirectUri}`,
    avatarEndpoint: defaultExternalOpenIdConfig.avatarEndpoint,
    scope: 'openid profile email', // scope: 'openid profile email address phone',
    response_type: 'code',
    extraQueryParams: {
      claims,
    },
    extraTokenParams: {
      claims,
    },
    metadataSeed: {
      end_session_endpoint: `${ssoBaseURL.href}/auth-ui/logout?client_id=${defaultExternalOpenIdConfig.clientId}`,
      revocation_endpoint: `${ssoBaseURL.href}/login/token/revoke?client_id=${defaultExternalOpenIdConfig.clientId}`,
    },
    popupWindowFeatures: getCenterPopupFeatures(500, 700),
    loadUserInfo: true,
    revokeAccessTokenOnSignout: true,
    revokeTokensOnSignout: false,
    onSigninPopupCallback: signinPopupCallback,
    onSigninRedirectCallback: signinRedirectCallback,
    onSigninCallback: signinCallback,
    onRemoveUser: removeUserCallback,
    monitorAnonymousSession: true,
    userStore: new WebStorageStateStore({
      store: createUserStorage(cookieStorage),
    }),
  };
};
