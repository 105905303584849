const getProfilePicture = (photos, avatarEndpoint) => {
  if (!photos) return null;

  const userAvatar = photos.find((photo) => photo.type === 'avatar');

  if (userAvatar?.value) {
    return `${avatarEndpoint}/${userAvatar.value}`;
  }

  const socialAvatar = photos.find((photo) => photo.type === 'normal');

  if (socialAvatar) {
    return socialAvatar.value;
  }

  const googleAvatar = photos.find((photo) => photo.type === 'original');

  if (googleAvatar) {
    return googleAvatar.value;
  }

  return null;
};

export default getProfilePicture;
